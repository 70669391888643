<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="批次编码：" prop="name">
                    <el-input v-model="queryParams.condition.batchCode" placeholder="请输入批次编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="厂商编码：" prop="manufacturerCode">
                    <el-input v-model="queryParams.condition.manufacturerCode" placeholder="请输入厂商编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="设备编码：" prop="serialNumber">
                    <el-input v-model="queryParams.condition.serialNumber" placeholder="请输入设备编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="SIM卡号：" prop="simNumber">
                    <el-input v-model="queryParams.condition.simNumber" placeholder="请输入SIM卡号" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="mb8">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleDeviceBinding">导入设备绑定数据</el-button>
            </div>
            <el-table v-loading="loading" :data="deviceList">
                <el-table-column label="设备号" align="center" key="serialNumber" prop="serialNumber" />
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="设备SIM号" align="center" key="simNumber" prop="simNumber" />
                <el-table-column label="设备CCID号" align="center" key="ccid" prop="ccid" />
                <el-table-column label="设备状态" align="center" key="status" prop="status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 0"> 未知 </span>
                        <span v-else-if="scope.row.status === 1"> 已入库 </span>
                        <span v-else-if="scope.row.status === 2"> 已出库 </span>
                    </template>
                </el-table-column>
                <el-table-column label="设备是否测试" align="center" key="sampleTest" prop="sampleTest">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sampleTets"> 已经测试 </span>
                        <span v-else-if="!scope.row.sampleTets"> 未测试 </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                    <el-row>
                        <el-button size="mini" type="text" icon="el-icon-search" @click="handleSampleTestOpen(scope.row)"> 查看行程 </el-button>
                        <el-button size="mini" type="text" icon="el-icon-search" @click="handleStore(scope.row)">查看实时点位</el-button>
                    </el-row>
                </el-table-column>
            </el-table>

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getDeviceList" />
        </el-row>

        <!-- 添加批次对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="设备Excel" prop="file">
                    <el-upload class="upload-demo" ref="upload" :action="this.baseURL" :on-change="handleFileChange" :auto-upload="false">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="beforeUpload(file)" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'Device',
    data() {
        return {
            title: '添加',
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 厂商表格数据
            manufacturerList: null,
            // 设备批次列表
            batchList: [],
            // 弹出层标题
            title: '',

            baseURL: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            file: null,
            fileString: '',
            fileList: [],

            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    manufacturerCode: undefined,
                    simNumber: undefined,
                    serialNumber: undefined
                }
            },
            // 表单校验
            rules: {
                batchCode: [
                    { required: true, message: '设备批次号不能为空', trigger: 'blur' },
                    { min: 2, max: 40, message: '设备批次号长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                model: [{ required: true, message: '设备型号不能为空', trigger: 'blur' }],
                count: [
                    { required: true, message: '设备数量不能为空', trigger: 'blur' },
                    { type: 'number', message: '设备数量必须为数字值', trigger: 'blur' }
                ],
                manufacturerCode: [{ required: true, message: '设备厂商不能为空', trigger: 'blur' }]
            },

            sampleOpen: false,
            sampleTitle: '选取设备',
            sampleForm: {
                batchCode: undefined,
                serialNumber: undefined
            },
            sampleTotal: 0,
            sampleQueryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    serialNumber: undefined
                }
            },
            deviceList: [],
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.getDeviceList();
    },
    methods: {
        /** 查询设备列表 */
        getDeviceList() {
            this.loading = true;
            let queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams.condition,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/operate/api/device/paging', json).then((res) => {
                this.loading = false;
                this.deviceList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },

        /** 新增按钮操作 */
        handleDeviceBinding() {
            this.reset();
            this.open = true;
            this.title = '导入设备绑定数据';
            this.file = undefined;
        },
        handleFileChange(file, fileList) {
            this.file = file.raw;
        },
        beforeUpload(file) {
            this.disabled = true;
            let _this = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                _this.fileString = reader.result.replace('data:', '').replace(/^.+,/, '');
                _this.submitForm();
            };
            this.disabled = false;
            return false;
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = JSON.parse(JSON.stringify(this.form));
                    param.file = this.fileString;
                    this.instance
                        .post('/operate/api/device/bind/add', param)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$modal.msgSuccess('导入成功');
                                this.open = false;
                                this.getDeviceList();
                                this.reset();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$modal.msgError('请求失败! 请稍后重试');
                        });
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                file: undefined
            };
            this.disabled = false;
            this.resetForm('form');
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getDeviceList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    manufacturerCode: undefined,
                    simNumber: undefined,
                    serialNumber: undefined
                }
            };
            this.handleQuery();
        }
    }
};
</script>