<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>
                        <el-select v-model="rootTenantId" placeholder="请选择" @change="changeTenant">
                            <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item style="line-height: 30px">设备业务管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号/车牌号/VIN">
                            <el-input v-model="queryParams.condition.search" placeholder="请输入设备号/车牌号/VIN" clearable />
                        </el-form-item>
                        <el-form-item label="续费状态">
                            <el-select v-model="queryParams.condition.renewStatus" placeholder="请选择变更状态" multiple clearable>
                                <el-option label="即将续费" :value="1001" :key="1001"></el-option>
                                <el-option label="过期未续费" :value="1002" :key="1002"></el-option>
                                <el-option label="已续费" :value="1000" :key="1000"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择分公司">
                            <el-select v-model="queryParams.condition.subTenantId" placeholder="请选择分公司" multiple clearable>
                                <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="到期日期">
                            <el-date-picker v-model="expirationTime" @change="(val) => pickerChange(1, val)" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <el-table :data="bindRecordList">
                    <el-table-column label="设备号" align="center" prop="deviceNumber" width="120">
                        <template slot-scope="scope">
                            <el-link @click="search(scope.row.deviceNumber)" type="primary">{{ scope.row.deviceNumber }}</el-link>
                        </template>
                    </el-table-column>
                  <el-table-column label="车牌号" align="center" prop="plateNo">
                    <template slot-scope="scope">
                      <div class="txtLink" @click="search(scope.row.plateNo)">
                        <overflow-txt :txt="scope.row.plateNo"></overflow-txt>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="车架号" align="center" prop="vin">
                    <template slot-scope="scope">
                      <div class="txtLink" @click="search(scope.row.vin)">
                        <overflow-txt :txt="scope.row.vin"></overflow-txt>
                      </div>
                    </template>
                  </el-table-column>
                    <el-table-column label="续费状态" align="center" key="bindStatus">
                        <template slot-scope="scope">
                            {{ scope.row.renewStatus == '1000' ? '已续费' : scope.row.renewStatus == '1001' ? '即将续费' : scope.row.renewStatus == '1002' ? '过期未续费' : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="到期时间" align="center" prop="expirationTime" width="140" />

                    <el-table-column label="所属分公司" align="center" key="subTenantName" prop="subTenantName">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.subTenantName"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column label="所属总公司" align="center" key="rootTenantName" prop="rootTenantName">
                        {{ rootTenantName }}
                    </el-table-column>
                    <el-table-column label="操作人" align="center" key="operator" prop="operator" />
                    <el-table-column label="操作时间" align="center" prop="createTime" width="140" />
                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchBindRecordList" />
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'deviceManager',
    data() {
        return {
            renewTime: '',
            expirationTime: '',
            baseURL: '',
            loading: false,
            recordFormVisible: false,
            uploadRecordsVisible: false,
            tenants: [
                { label: '环球车享', value: 10004 },
                { label: '太保商用车', value: 10049 }
            ],
            rootTenantId: 10004,
            rootTenantName: '环球车享',
            companyList: [],
            fleetList: [],
            dataRange: [],
            queryParams: {
                condition: {
                    rootTenantId: this.rootTenantId,
                    search: null,
                    renewStatus: [],
                    subTenantId: [],
                    renewTimeMin: null,
                    renewTimeMax: null,
                    expirationTimeMin: null,
                    expirationTimeMax: null
                },
                pageNo: 1,
                pageSize: 10
            },
            bindRecordList: [],
            total: 0,
            bindRecordForm: {
                deviceNumber: null,
                renewTime: null,
                expirationTime: null,
                plateNo: null,
                vin: null,
                subTenantId: null
            },
            uploadForm: {},
            file: null,
            fileList: [],
            rules: {
                deviceNumber: [
                    { required: true, message: '设备号不能为空', trigger: 'blur' },
                    { min: 10, max: 15, message: '设备号长度在 10 到 15 位', trigger: 'blur' }
                ],
                expirationTime: [{ required: true, message: '到期日期不能为空', trigger: 'blur' }],
                vin: [
                    { required: true, message: '车架号不能为空', trigger: 'blur' },
                    { min: 17, max: 17, message: '车架号长度为17位', trigger: 'blur' }
                ],
                plateNo: [
                    { required: true, message: '车牌号不能为空', trigger: 'blur' },
                    { min: 7, max: 8, message: '车牌号长度在 7 到 8 位', trigger: 'blur' }
                ],
                subTenantId: [{ required: true, message: '分公司不能为空', trigger: ['blur', 'change'] }],
                bindTime: [{ required: true, message: '变更时间不能为空', trigger: 'blur' }]
            }
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.fetchCompanyList();
        this.fetchBindRecordList();
    },
    methods: {
        pickerChange(str, val) {
            if (str == 0) {
                // 续费时间
                this.queryParams.condition.renewTimeMin = val && val[0] ? val[0] + ' 00:00:00' : null;
                this.queryParams.condition.renewTimeMax = val && val[1] ? val[1] + ' 23:59:59' : null;
            } else {
                // 到期时间
                this.queryParams.condition.expirationTimeMin = val && val[0] ? val[0] + ' 00:00:00' : null;
                this.queryParams.condition.expirationTimeMax = val && val[1] ? val[1] + ' 23:59:59' : null;
            }
        },
        downloadTemplate() {},
        uploadRecords() {
            this.fileList = [];
            this.file = null;
            this.uploadRecordsVisible = true;
        },

        handleRemove(file, fileList) {
            this.file = undefined;
            this.fileList = [];
        },


        doAddBindRecord(submitFlag) {
            if (submitFlag) {
                this.$refs.bindRecordForm.validate((valid) => {
                    if (valid) {
                        this.submitBindRecord();
                    }
                });
            } else {
                this.$refs.bindRecordForm && this.$refs.bindRecordForm.resetFields();
                this.recordFormVisible = false;
            }
        },


        fetchCompanyList() {
            this.instance.get('/operate/api/tenant/companyList?rootTenantId=' + this.rootTenantId).then((res) => {
                this.companyList = res.data.data;
            });
        },
        fetchBindRecordList() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.queryParams));
            this.instance.post('/operate/api/device/deviceBusinessPaging', data).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.bindRecordList = res.data.data.list;
                    this.total = parseInt(res.data.data.total);
                }
            });
        },
        search(searchText) {
            this.reset();
            this.queryParams = {
                condition: {
                    rootTenantId: this.rootTenantId,
                    search: searchText,
                    renewStatus: [],
                    subTenantId: [],
                    renewTimeMin: null,
                    renewTimeMax: null,
                    expirationTimeMin: null,
                    expirationTimeMax: null
                },
                pageNo: 1,
                pageSize: 10
            };
            this.fetchBindRecordList();
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.fetchBindRecordList();
        },
        resetQuery() {
            this.queryParams = {
                condition: {
                    rootTenantId: this.rootTenantId,
                    search: null,
                    renewStatus: [],
                    subTenantId: [],
                    renewTimeMin: null,
                    renewTimeMax: null,
                    expirationTimeMin: null,
                    expirationTimeMax: null
                },
                pageNo: 1,
                pageSize: 10
            };
            this.renewTime = '';
            this.expirationTime = '';
            this.dataRange = [];
        },
        reset() {
            this.resetQuery();
            this.bindRecordList = [];
            this.fileList = [];
            this.file = undefined;
        },
        changeTenant(rootTenantId) {
            this.rootTenantId = rootTenantId;
            this.rootTenantName = rootTenantId == 10004 ? '环球车享' : '太保商用车';
            this.queryParams.condition = rootTenantId;
            this.fetchCompanyList();
            this.resetWithLoadRecord();
        },
        resetWithLoadRecord() {
            this.reset();
            this.fetchBindRecordList();
        }
    }
};
</script>