<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="设备号：" prop="deviceNumber">
                    <el-input v-model="queryParams.deviceNumber" placeholder="请输入设备号" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="开始时间：" prop="startTime">
                    <el-input v-model="queryParams.startTime" placeholder="请输入开始时间" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="结束时间：" prop="endTime">
                    <el-input v-model="queryParams.endTime" placeholder="请输入结束时间" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="downloadPoints">下载 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import gpsPoint from '@/views/device/gpsPoint.vue';

export default {
    name: 'GpsPoint',
    computed: {
        gpsPoint() {
            return gpsPoint;
        }
    },
    components: { Treeselect },
    data() {
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 厂商表格数据
            manufacturerList: null,
            // 设备批次列表
            batchList: [],
            // 弹出层标题
            title: '',

            baseURL: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            tenantOptions: [],

            shipmentDeviceList: [],

            file: null,
            fileString: '',
            fileList: [],

            // 查询参数
            queryParams: {
                deviceNumber: undefined,
                startTime: undefined,
                endTime: undefined
            },
            // 表单校验
            rules: {
                deviceNumber: [{ required: true, message: '设备号不能为空', trigger: 'blur' }],
                startTime: [{ required: true, message: '开始时间不能为空', trigger: 'blur' }],
                endTime: [{ required: true, message: '结束时间不能为空', trigger: 'blur' }]
            },
            deviceList: [],

            rootTenantId: undefined,
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.getBatchList();
    },
    methods: {
        filterMethod(query, item) {
            return item.label.indexOf(query) > -1;
        },
        /** 选取树状结构找顶级 id */
        handleTreeSelect(selectedItems) {
            if (selectedItems.parentTenantId !== 0) {
                const selected = selectedItems;
                let ancestor = selected.parent;
                while (ancestor) {
                    if (!ancestor.parent) {
                        this.rootTenantId = ancestor.id;
                        break;
                    }
                    ancestor = ancestor.parent;
                }
            } else {
                this.rootTenantId = 0;
            }
        },

        downloadPoints() {
            const path = this.baseURL + `/operate/api/point/downloadPointMessageRecordstxt?deviceNumber=${this.queryParams.deviceNumber}&startTime=${this.queryParams.startTime}&endTime=${this.queryParams.endTime}`;
            window.open(path, '_blank');
        }
    }
};
</script>