<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="批次编码：" prop="name">
                    <el-input v-model="queryParams.condition.batchCode" placeholder="请输入批次编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="设备编码：" prop="serialNumber">
                    <el-input v-model="queryParams.condition.serialNumber" placeholder="请输入设备编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="mb8">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleSampleTest">添加测试结论 </el-button>
            </div>
            <el-table v-loading="loading" :data="deviceList" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column label="设备号" align="center" key="serialNumber" prop="serialNumber" />
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="测试时间" align="center">
                    <template slot-scope="scope">
                        <span>
                            开始时间: {{ scope.row.testStartTime }} <br />
                            结束时间: {{ scope.row.testEndTime }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="测试结论" align="center" key="testResult" prop="testResult">
                    <template slot-scope="scope">
                        <span v-if="scope.row.testResult === 0"> 未知 </span>
                        <span v-else-if="scope.row.testResult === 1"> 通过 </span>
                        <span v-else-if="scope.row.testResult === 2"> 不通过 </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-search" @click="handleSampleTestOpen(scope.row)"> 查看行程 </el-button>
                        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleSampleTestByOne(scope.row)">添加测试结论 </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getDeviceList" />
        </el-row>

        <!-- 添加批次对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="测试结论" prop="testResult">
                    <el-select v-model="form.testResult" placeholder="请选择测试结论">
                        <el-option v-for="item in testResultList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input type="textarea" v-model="form.remarks" placeholder="请输入测试备注" />
                </el-form-item>

                <el-form-item label="测试结束日期" prop="testEndTime">
                    <el-date-picker v-model="form.testEndTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%" placeholder="选择日期时间"> </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'SampleTest',
    data() {
        return {
            title: '添加',
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 设备批次列表
            batchList: [],
            // 弹出层标题
            title: '',

            baseURL: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            testResultList: [
                {
                    label: '通过',
                    value: 1
                },
                {
                    label: '不通过',
                    value: 2
                }
            ],

            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    manufacturerCode: undefined,
                    simNumber: undefined,
                    serialNumber: undefined
                }
            },
            // 表单校验
            rules: {
                testResult: [{ required: true, message: '设备批次号不能为空', trigger: 'blur' }],
                remarks: [{ required: true, message: '备注不能为空', trigger: 'blur' }],
                testEndTime: [{ required: true, message: '设备测试结束时间不能为空', trigger: 'blur' }]
            },
            deviceList: []
        };
    },
    watch: {},
    mounted() {},
    created() {
        this.getDeviceList();
    },
    methods: {
        /** 查询设备列表 */
        getDeviceList() {
            this.loading = true;
            let queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams.condition,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/operate/api/sample/test/paging', json).then((res) => {
                this.loading = false;
                this.deviceList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },

        /** 添加测试结论按钮操作 */
        handleSampleTest() {
            this.reset();
            this.open = true;
            this.title = '添加设备测试结论';
            this.file = undefined;
        },
        /** 添加测试结论按钮操作 */
        handleSampleTestByOne(row) {
            this.reset();
            this.open = true;
            this.title = '添加设备测试结论';
            this.ids = [row.id];
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = JSON.parse(JSON.stringify(this.form));
                    param.ids = this.ids;
                    this.instance
                        .post('/operate/api/sample/test/batchUpdate', param)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$modal.msgSuccess('修改测试结论成功');
                                this.open = false;
                                this.getDeviceList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$modal.msgError('请求失败,请稍后重试');
                        });
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                testResult: undefined,
                remarks: undefined,
                testEndTime: undefined
            };
            this.disabled = false;
            this.fileString = undefined;
            this.resetForm('form');
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getDeviceList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    serialNumber: undefined
                }
            };
            this.handleQuery();
        }
    }
};
</script>