<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="厂商名称：" prop="name">
                    <el-input v-model="queryParams.name" placeholder="请输入厂商名称" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="厂商编码：" prop="manufacturerCode">
                    <el-input v-model="queryParams.manufacturerCode" placeholder="请输入厂商编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="mb8">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
                <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
            </div>
            <el-table v-loading="loading" :data="manufacturerList">
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="厂商名称" align="center" key="name" prop="name" />
                <el-table-column label="厂商联系人" align="center" key="contact" prop="contact" />
                <el-table-column label="厂商地址" align="center" key="address" prop="address" />
                <el-table-column label="厂商联系电话" align="center" key="phone" prop="phone" />
            </el-table>

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />
        </el-row>

        <!-- 添加或修改用户配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="厂商名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入厂商名称" maxlength="30" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="厂商联系人" prop="contact">
                            <el-input v-model="form.contact" placeholder="请输入厂商联系人" maxlength="11" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="厂商联系人电话" prop="phone">
                            <el-input v-model="form.phone" placeholder="请输入厂商联系人电话" maxlength="11" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="厂商地址" prop="phone">
                            <el-input v-model="form.address" placeholder="请输入厂商地址" maxlength="255" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'Manufacturer',
    data() {
        return {
            title: '添加',
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 厂商表格数据
            manufacturerList: null,
            // 弹出层标题
            title: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                manufacturerCode: undefined,
                name: undefined
            },
            // 表单校验
            rules: {
                name: [
                    { required: true, message: '厂商名称不能为空', trigger: 'blur' },
                    { min: 2, max: 40, message: '厂商名称长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                contact: [{ required: true, message: '厂商联系人不能为空', trigger: 'blur' }]
            }
        };
    },
    watch: {
        // 根据名称筛选部门树
        deptName(val) {
            this.$refs.tree.filter(val);
        }
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询用户列表 */
        getList() {
            this.loading = true;
            let queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/operate/api/manufacturer/paging', json).then((res) => {
                this.loading = false;
                this.manufacturerList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },

        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加厂商';
        },
        /** 提交按钮 */
        submitForm() {
            this.disabled = true;
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.instance
                        .post('/operate/api/manufacturer/add', this.form)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        })
                        .catch((res) => {
                            this.$modal.msgError('请求失败! 请稍后重试');
                        });
                }
            });
            this.disabled = false;
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                name: undefined,
                address: undefined,
                contact: undefined,
                email: undefined,
                phone: undefined
            };
            this.disabled = false;
            this.resetForm('form');
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.queryParams.deptId = undefined;
            this.$refs.tree.setCurrentKey(null);
            this.handleQuery();
        }
    }
};
</script>