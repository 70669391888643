<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="批次编码：" prop="name">
                    <el-input v-model="queryParams.condition.batchCode" placeholder="请输入批次编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="厂商编码：" prop="manufacturerCode">
                    <el-input v-model="queryParams.condition.manufacturerCode" placeholder="请输入厂商编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="mb8">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
                <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
            </div>
            <el-table v-loading="loading" :data="batchList">
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="客户名称" align="center" key="rootTenantName" prop="rootTenantName" />
                <el-table-column label="客户车队名称" align="center" key="tenantName" prop="tenantName" />
                <el-table-column label="设备数量" align="center" key="count" prop="count" />
                <el-table-column label="出货单创建人" align="center" key="createBy" prop="createBy" />
                <el-table-column label="出货时间" align="center" key="createTime" prop="createTime" />
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleShipmentDetail(scope.row)"> 详情 </el-button>
                        <el-button size="mini" type="text" icon="el-icon-document-checked" @click="handleAsync(scope.row)">同步其他系统 </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getBatchList" />
        </el-row>

        <!-- 添加批次对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="租户" prop="tenantId">
                    <treeselect v-model="form.tenantId" :options="tenantOptions" :show-count="true" :clear-on-select="true" :emit-change-on-select="true" @select="handleTreeSelect" placeholder="请选择归属租户" />
                </el-form-item>
                <el-form-item label="设备数量" prop="count">
                    <el-input v-model.number="form.count" placeholder="请输入收货数量" @blur="getDeviceList" maxlength="11" />
                </el-form-item>
                <el-form-item label="设备厂商" prop="manufacturerCode">
                    <el-select v-model="form.manufacturerCode" placeholder="请选择设备厂商">
                        <el-option v-for="item in manufacturerList" :key="item.manufacturerCode" :label="item.name" :value="item.manufacturerCode"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备" prop="serialNumbers">
                    <el-transfer filterable :filter-method="filterMethod" filter-placeholder="请输入设备号" :titles="['源列表', '目标列表']" v-model="form.serialNumbers" :data="shipmentDeviceList"> </el-transfer>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 测试批次对话框 -->
        <el-dialog :title="shipmentTitle" :visible.sync="shipmentOpen" width="960px" append-to-body>
            <el-form ref="shipmentForm" :model="shipmentForm" :rules="rules" label-width="120px">
                <el-row>
                    <el-col span="8">
                        <el-form-item label="发货批次编码" prop="shipmentBatchCode">
                            <el-input v-model="shipmentForm.shipmentBatchCode" placeholder="请输入发货批次运单号" maxlength="30" disabled />
                        </el-form-item>
                    </el-col>
                    <el-col span="8">
                        <el-form-item label="设备号" prop="serialNumber">
                            <el-input v-model="shipmentForm.serialNumber" placeholder="请输入设备号" maxlength="20" />
                        </el-form-item>
                    </el-col>
                    <el-col span="8">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleShipmentQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetShipmentQuery">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-table v-loading="loading" :data="deviceList">
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="设备号" align="center" key="serialNumber" prop="serialNumber" />
                <el-table-column label="设备SIM号" align="center" key="simNumber" prop="simNumber" />
                <el-table-column label="设备CCID号" align="center" key="ccid" prop="ccid" />
                <el-table-column label="设备状态" align="center" key="status" prop="status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 0"> 未知 </span>
                        <span v-else-if="scope.row.status === 1"> 已入库 </span>
                        <span v-else-if="scope.row.status === 2"> 已出库 </span>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="shipmentTotal > 0" :total="shipmentTotal" :page.sync="shipmentQueryParams.pageNo" :limit.sync="shipmentQueryParams.pageSize" @pagination="getShipmentDeviceList" />
        </el-dialog>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import shipment from '@/views/device/shipment.vue';

export default {
    name: 'Shipment',
    computed: {
        shipment() {
            return shipment;
        }
    },
    components: { Treeselect },
    data() {
        return {
            title: '添加',
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 厂商表格数据
            manufacturerList: null,
            // 设备批次列表
            batchList: [],
            // 弹出层标题
            title: '',

            baseURL: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            tenantOptions: [],

            shipmentDeviceList: [],

            file: null,
            fileString: '',
            fileList: [],

            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    manufacturerCode: undefined,
                    model: undefined
                }
            },
            // 表单校验
            rules: {
                tenantId: [{ required: true, message: '设备批次号不能为空', trigger: 'blur' }],
                count: [
                    { required: true, message: '设备数量不能为空', trigger: 'blur' },
                    { type: 'number', message: '设备数量必须为数字值', trigger: 'blur' }
                ],
                manufacturerCode: [{ required: true, message: '设备厂商不能为空', trigger: 'blur' }]
            },

            shipmentOpen: false,
            shipmentTitle: '设备',
            shipmentForm: {
                shipmentBatchCode: undefined,
                serialNumber: undefined
            },
            shipmentTotal: 0,
            shipmentQueryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    shipmentBatchCode: undefined,
                    serialNumber: undefined
                }
            },
            deviceList: [],

            rootTenantId: undefined,
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.getBatchList();
        this.getTenantTree();
        this.getList();
    },
    methods: {
        filterMethod(query, item) {
            return item.label.indexOf(query) > -1;
        },
        /** 选取树状结构找顶级 id */
        handleTreeSelect(selectedItems) {
            if (selectedItems.parentTenantId !== 0) {
                const selected = selectedItems;
                let ancestor = selected.parent;
                while (ancestor) {
                    if (!ancestor.parent) {
                        this.rootTenantId = ancestor.id;
                        break;
                    }
                    ancestor = ancestor.parent;
                }
            } else {
                this.rootTenantId = 0;
            }
        },

        // 查看发货单详情
        handleShipmentDetail(row) {
            this.shipmentOpen = true;
            this.shipmentForm.shipmentBatchCode = row.batchCode;
            this.handleShipmentQuery();
        },

        // 查询租户信息
        getTenantTree() {
            let param = {
                pageNo: 1,
                pageSize: 100,
                condition: {}
            };
            this.instance.post('/upms/api/tenant/paging', param).then((res) => {
                if (res.data.code === 200) {
                    let response = res.data.data.list;

                    function addLabel(items, parent) {
                        if (items) {
                            items.forEach((val) => {
                                val.label = val.tenantName;
                                val.children = val.subTenantVoList;
                                val.parent = parent;
                                if (val.subTenantVoList == null) {
                                    delete val.subTenantVoList;
                                    delete val.children;
                                }
                                val.children && addLabel(val.subTenantVoList, val);
                            });
                        } else {
                            return false;
                        }
                    }

                    addLabel(response, null);
                    this.tenantOptions = response;
                }
            });
        },

        getList() {
            let json = {
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/operate/api/manufacturer/paging', json).then((res) => {
                this.manufacturerList = res.data.data.list;
            });
        },

        /** 查询用户列表 */
        getBatchList() {
            this.loading = true;
            let queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams.condition,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/operate/api/shipment/batch/paging', json).then((res) => {
                this.loading = false;
                this.batchList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },

        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加设备批次';
            this.shipmentDeviceList = [];
            this.rootTenantId = undefined;
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = JSON.parse(JSON.stringify(this.form));
                    param.rootTenantId = this.rootTenantId;
                    this.instance
                        .post('/operate/api/shipment/batch/create', param)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getBatchList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$modal.msgError('请求失败! 请稍后重试');
                        });
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                tenantId: undefined,
                count: undefined,
                manufacturerCode: undefined,
                serialNumbers: undefined,
                rootTenantId: undefined
            };
            this.disabled = false;
            this.fileString = undefined;
            this.resetForm('form');
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getBatchList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.handleQuery();
        },

        getDeviceList() {
            let param = {
                pageNo: 1,
                pageSize: this.form.count,
                condition: {
                    manufacturerCode: this.form.manufacturerCode,
                    status: 1
                }
            };

            this.shipmentDeviceList = [];

            this.instance.post('/operate/api/device/paging', param).then((res) => {
                this.loading = false;
                this.deviceList = res.data.data.list;
                this.deviceList.forEach((device, index) => {
                    this.shipmentDeviceList.push({
                        label: device.serialNumber,
                        key: device.serialNumber
                    });
                });
            });
        },

        handleAsync(row) {
            this.$confirm('确认同步？')
                .then((_) => {
                    this.instance
                        .post('/operate/api/shipment/batch/sync/' + row.batchCode, {})
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$message.info('同步成功!');
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$message.error('操作同步失败');
                        });
                })
                .catch((_) => {});
        },

        getShipmentDeviceList() {
            let param = {
                pageNo: this.shipmentQueryParams.pageNo,
                pageSize: this.shipmentQueryParams.pageSize,
                condition: {
                    shipmentBatchCode: this.shipmentForm.shipmentBatchCode,
                    serialNumber: this.shipmentForm.serialNumber,
                    status: 2
                }
            };

            this.instance.post('/operate/api/device/paging', param).then((res) => {
                this.loading = false;
                this.deviceList = res.data.data.list;
                this.shipmentTotal = parseInt(res.data.data.total);
            });
        },

        /** 搜索按钮操作 */
        handleShipmentQuery() {
            this.shipmentQueryParams.pageNo = 1;
            this.getShipmentDeviceList();
        },
        /** 重置按钮操作 */
        resetShipmentQuery() {
            this.dateRange = [];
            this.resetForm('shipmentForm');
            this.handleShipmentQuery();
        }
    }
};
</script>