<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--用户数据-->

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
                <el-form-item label="批次编码：" prop="name">
                    <el-input v-model="queryParams.condition.batchCode" placeholder="请输入批次编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="厂商编码：" prop="manufacturerCode">
                    <el-input v-model="queryParams.condition.manufacturerCode" placeholder="请输入厂商编码" clearable class="width_170" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="mb8">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
                <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
            </div>
            <el-table v-loading="loading" :data="batchList">
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="设备型号" align="center" key="model" prop="model" />
                <el-table-column label="设备数量" align="center" key="count" prop="count" />
                <el-table-column label="设备生产日期" align="center" key="productionDate" prop="productionDate" />
                <el-table-column label="设备是否测试" align="center" key="sampleTest" prop="sampleTest" />
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleSampleTestOpen(scope.row)"> 测试 </el-button>
                        <el-button size="mini" type="text" icon="el-icon-document-checked" @click="handleStore(scope.row)">入库</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getBatchList" />
        </el-row>

        <!-- 添加批次对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="设备批次编码" prop="batchCode">
                    <el-input v-model="form.batchCode" placeholder="请输入设备批次运单号" maxlength="30" />
                </el-form-item>
                <el-form-item label="设备型号" prop="model">
                    <el-input v-model="form.model" placeholder="请输入厂商联系人电话" maxlength="20" />
                </el-form-item>
                <el-form-item label="设备数量" prop="count">
                    <el-input v-model.number="form.count" placeholder="请输入收货数量" maxlength="11" />
                </el-form-item>
                <el-form-item label="设备厂商" prop="manufacturerCode">
                    <el-select v-model="form.manufacturerCode" placeholder="请选择设备厂商">
                        <el-option v-for="item in manufacturerList" :key="item.manufacturerCode" :label="item.name" :value="item.manufacturerCode"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备生产日期" prop="productionDate">
                    <el-date-picker v-model="form.productionDate" type="date" value-format="yyyy-MM-dd" style="width: 100%" placeholder="选择日期时间"> </el-date-picker>
                </el-form-item>
                <el-form-item label="设备Excel" prop="file">
                    <el-upload class="upload-demo" ref="upload" :action="this.baseURL" :on-change="handleFileChange" :auto-upload="false">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="beforeUpload(file)" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 测试批次对话框 -->
        <el-dialog :title="sampleTitle" :visible.sync="sampleOpen" width="960px" append-to-body>
            <el-form ref="sampleForm" :model="sampleForm" :rules="rules" label-width="120px">
                <el-row>
                    <el-col span="8">
                        <el-form-item label="设备批次编码" prop="batchCode">
                            <el-input v-model="sampleForm.batchCode" placeholder="请输入设备批次运单号" maxlength="30" disabled />
                        </el-form-item>
                    </el-col>
                    <el-col span="8">
                        <el-form-item label="设备号" prop="serialNumber">
                            <el-input v-model="sampleForm.serialNumber" placeholder="请输入设备号" maxlength="20" />
                        </el-form-item>
                    </el-col>
                    <el-col span="8">
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleSampleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetSampleQuery">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-table v-loading="loading" :data="deviceList" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column label="批次编号" align="center" key="batchCode" prop="batchCode" />
                <el-table-column label="厂商编号" align="center" key="manufacturerCode" prop="manufacturerCode" />
                <el-table-column label="设备号" align="center" key="serialNumber" prop="serialNumber" />
                <el-table-column label="设备SIM号" align="center" key="simNumber" prop="simNumber" />
                <el-table-column label="设备CCID号" align="center" key="ccid" prop="ccid" />
                <el-table-column label="设备状态" align="center" key="status" prop="status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 0"> 未知 </span>
                        <span v-else-if="scope.row.status === 1"> 已入库 </span>
                        <span v-else-if="scope.row.status === 2"> 已出库 </span>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="sampleTotal > 0" :total="sampleTotal" :page.sync="sampleQueryParams.pageNo" :limit.sync="sampleQueryParams.pageSize" @pagination="sampleQuery" />

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSampleTest" :disabled="disabled">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'Batch',
    data() {
        return {
            title: '添加',
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 厂商表格数据
            manufacturerList: null,
            // 设备批次列表
            batchList: [],
            // 弹出层标题
            title: '',

            baseURL: '',
            // 是否显示弹出层
            open: false,
            // 提交按钮禁用
            disabled: false,
            // 表单参数
            form: {},

            file: null,
            fileString: '',
            fileList: [],

            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    manufacturerCode: undefined,
                    model: undefined
                }
            },
            // 表单校验
            rules: {
                batchCode: [
                    { required: true, message: '设备批次号不能为空', trigger: 'blur' },
                    { min: 2, max: 40, message: '设备批次号长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                model: [{ required: true, message: '设备型号不能为空', trigger: 'blur' }],
                count: [
                    { required: true, message: '设备数量不能为空', trigger: 'blur' },
                    { type: 'number', message: '设备数量必须为数字值', trigger: 'blur' }
                ],
                manufacturerCode: [{ required: true, message: '设备厂商不能为空', trigger: 'blur' }]
            },

            sampleOpen: false,
            sampleTitle: '选取设备',
            sampleForm: {
                batchCode: undefined,
                serialNumber: undefined
            },
            sampleTotal: 0,
            sampleQueryParams: {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    batchCode: undefined,
                    serialNumber: undefined
                }
            },
            deviceList: [],
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        this.getBatchList();
        this.getList();
    },
    methods: {
        /** 查询用户列表 */
        getBatchList() {
            this.loading = true;
            let queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams.condition,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/operate/api/batch/paging', json).then((res) => {
                this.loading = false;
                this.batchList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        /** 查询用户列表 */
        getList() {
            let json = {
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/operate/api/manufacturer/paging', json).then((res) => {
                this.manufacturerList = res.data.data.list;
            });
        },

        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加设备批次';
            this.file = undefined;
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = JSON.parse(JSON.stringify(this.form));
                    param.file = this.fileString;
                    this.instance
                        .post('/operate/api/batch/add', param)
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getBatchList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$modal.msgError('请求失败! 请稍后重试');
                        });
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                batchCode: undefined,
                manufacturerCode: undefined,
                count: undefined,
                file: undefined,
                model: undefined,
                productionDate: undefined
            };
            this.disabled = false;
            this.fileString = undefined;
            this.resetForm('form');
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getBatchList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.handleQuery();
        },

        handleFileChange(file, fileList) {
            this.file = file.raw;
        },
        beforeUpload(file) {
            this.disabled = true;
            let _this = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                _this.fileString = reader.result.replace('data:', '').replace(/^.+,/, '');
                _this.submitForm();
            };
            this.disabled = false;
            return false;
        },

        handleSampleTestOpen(row) {
            this.sampleOpen = true;
            this.sampleForm.batchCode = row.batchCode;
            this.sampleQuery();
        },

        sampleQuery() {
            let param = {
                pageNo: this.sampleQueryParams.pageNo,
                pageSize: this.sampleQueryParams.pageSize,
                condition: {
                    batchCode: this.sampleForm.batchCode,
                    serialNumber: this.sampleForm.serialNumber,
                    sampleTest: false
                }
            };

            this.instance.post('/operate/api/device/paging', param).then((res) => {
                this.loading = false;
                this.deviceList = res.data.data.list;
                this.sampleTotal = parseInt(res.data.data.total);
            });
        },

        /** 搜索按钮操作 */
        handleSampleQuery() {
            this.sampleQueryParams.pageNo = 1;
            this.sampleQuery();
        },
        /** 重置按钮操作 */
        resetSampleQuery() {
            this.dateRange = [];
            this.resetForm('sampleForm');
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },

        handleSampleTest() {
            this.instance
                .post('/operate/api/sample/test/add', this.ids)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.sampleOpen = false;
                        this.$message.info('添加测试设备成功');
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.$message.error('操作失败');
                });
        },
        handleStore(row) {
            this.$confirm('确认入库？')
                .then((_) => {
                    this.instance
                        .post('/operate/api/batch/in/' + row.batchCode, {})
                        .then((res) => {
                            if (res.data.code === 200) {
                                this.$message.info('入库成功!');
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$message.error('操作入库失败');
                        });
                })
                .catch((_) => {});
        }
    }
};
</script>