<template>
    <div class="app-container">
        <el-card v-loading="loading">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="line-height: 30px">设备库存管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-row :gutter="40" style="margin-left: 10px">
                    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true">
                        <el-form-item label="设备号">
                            <el-input v-model="queryParams.condition.deviceNumber" placeholder="请输入设备号" clearable />
                        </el-form-item>
                        <el-form-item label="库存状态">
                            <el-select v-model="queryParams.condition.stockStatus" placeholder="请选择变更状态" clearable>
                                <el-option label="入库" value="0" key="0"></el-option>
                                <el-option label="出库" value="1" key="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="续费状态">
                            <el-select v-model="queryParams.condition.renewStatus" placeholder="请选择变更状态" clearable>
                                <el-option label="已续费" value="1000" key="1000"></el-option>
                                <el-option label="即将续费" value="1001" key="1001"></el-option>
                                <el-option label="过期未续费" value="1002" key="1002"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户总公司">
                            <el-select v-model="queryParams.condition.rootTenantId" placeholder="请选择客户总公司" @change="changeTenant" clearable>
                                <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户分公司">
                            <el-select style="width: 100%" v-model="queryParams.condition.subTenantId" placeholder="请选择客户分公司" @change="changeCompany" clearable> 
                                <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户车队">
                            <el-select style="width: 100%" v-model="queryParams.condition.fleetId" placeholder="请选择客户车队" clearable>
                                <el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
            
                        <el-form-item label="入库日期">
                            <el-date-picker 
                                v-model="queryParams.condition.inStorageTimeRange" 
                                type="daterange" 
                                format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期"> 
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="出库日期">
                            <el-date-picker 
                                v-model="queryParams.condition.outStorageTimeRange" 
                                type="daterange" 
                                format="yyyy-MM-dd" 
                                value-format="yyyy-MM-dd" 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期"> 
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索 </el-button>
                            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div>
                <div style="text-align: right; height: 40px">
                    <el-link :href="baseURL + '/operate/api/device/stockTemplate'" type="primary">下载导入模板：台账模板.xlsx</el-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button @click="uploadRecords" type="primary" icon="el-icon-upload2" size="mini">批量导入</el-button>
                    <el-button @click="addRecord" type="primary" icon="el-icon-plus" size="mini">新增记录</el-button>
                    <el-button @click="downloadTable" icon="el-icon-download" type="primary" size="mini"></el-button>
                </div>
                <el-table :data="recordList">
                    <el-table-column label="设备号" align="center" key="deviceNumber" prop="deviceNumber" fixed/>
                    <el-table-column label="SIM卡号" align="center" key="sim" prop="sim" />
                    <el-table-column label="ICCID" align="center" key="iccid" prop="iccid" />
                    <el-table-column label="VIN码" align="center" key="vin" prop="vin" />
                    <el-table-column label="车牌号" align="center" key="plateNo" prop="plateNo" />
                    <el-table-column label="设备型号" align="center" key="deviceModule" prop="deviceModule"/>
                    <el-table-column label="设备供应商" align="center" key="deviceSupplierName" prop="deviceSupplierName"/>
                    <el-table-column label="流量类型" align="center" key="internetTrafficType" prop="internetTrafficType" />
                    <el-table-column label="流量大小" align="center" key="internetTraffic" prop="internetTraffic">
                        <template slot-scope="scope">
                            {{ scope.row.internetTraffic }}M
                        </template>
                    </el-table-column>
                    <el-table-column label="采购时间" align="center" key="purchaseTime" prop="purchaseTime" />
                    <el-table-column label="厂家发货时间" align="center" key="supplierSendTime" prop="supplierSendTime" />

                    <el-table-column label="库存状态" align="center" key="stockStatus" prop="stockStatus">
                        <template slot-scope="scope">
                            {{ scope.row.stockStatus == 0 ? '入库' : '出库' }}
                        </template>
                    </el-table-column>

                    <el-table-column label="入库时间" align="center" key="inStorageTime" prop="inStorageTime" />
                    <el-table-column label="出库时间" align="center" key="outStorageTime" prop="outStorageTime" />
                    <el-table-column label="设备激活时间" align="center" key="activateTime" prop="activateTime" />
                    <el-table-column label="绑定车辆时间" align="center" key="bindTime" prop="bindTime" />

                    <el-table-column label="续费状态" align="center" key="renewStatus" prop="renewStatusName" />
    
                    <el-table-column label="续费时间" align="center" key="renewTime" prop="renewTime" />
                    <el-table-column label="厂商过期时间" align="center" key="supplierExpirationTime" prop="supplierExpirationTime" />
                    <el-table-column label="客户过期时间" align="center" key="tenantExpirationTime" prop="tenantExpirationTime" />

                    <el-table-column label="库管" align="center" key="outStorageTime" prop="warehouseOperator" />
                    <el-table-column label="质检" align="center" key="outStorageTime" prop="qualityInspector" />
                    <el-table-column label="业务操作员" align="center" key="outStorageTime" prop="businessOperator" />
                    <el-table-column label="客户分公司" align="center" key="subTenantName" prop="subTenantName" />
                    <el-table-column label="客户总公司" align="center" key="rootTenantName" prop="rootTenantName" />
                    <el-table-column label="设备状态" align="center" key="deviceStatusName" prop="deviceStatusName" />
                    
                    <el-table-column label="操作" align="center" key="operate" prop="operate" fixed="right">
                        <template slot-scope="scope">
                            <el-button 
                                style="margin-bottom: 5px;" 
                                @click="editRecord(scope.row)" 
                                type="primary"
                            >
                            编辑
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="fetchRecordList" />
            </div>
            <!-- 添加弹窗 -->
            <el-dialog :modal-append-to-body="false" title="变更记录" :visible.sync="recordFormVisible" width="800px" @close="doEditRecord(false)">
                <el-form :model="recordForm" ref="recordForm" size="small" label-width="100px" :rules="rules">
                    <el-form-item label="设备号" prop="deviceNumber">
                        <el-input v-model="recordForm.deviceNumber" maxlength="15" placeholder="请输入设备号" clearable />
                    </el-form-item>
                    <el-form-item label="SIM卡号" prop="sim">
                        <el-input v-model="recordForm.sim" maxlength="15" placeholder="请输入SIM卡号" clearable />
                    </el-form-item>
                    <el-form-item label="ICCID" prop="iccid">
                        <el-input v-model="recordForm.iccid" maxlength="15" placeholder="请输入ICCID" clearable />
                    </el-form-item>
                    <el-form-item label="车牌号" prop="plateNo">
                        <el-input v-model="recordForm.plateNo" maxlength="8" placeholder="请输入车牌号" clearable />
                    </el-form-item>
                    <el-form-item label="车架号" prop="vin">
                        <el-input v-model="recordForm.vin" maxlength="17" placeholder="请输入VIN码" clearable />
                    </el-form-item>
                    <el-form-item label="设备型号" prop="deviceModule">
                        <el-input v-model="recordForm.deviceModule" maxlength="17" placeholder="请输入设备型号" clearable />
                    </el-form-item>
                    <el-form-item label="设备供应商" prop="deviceSupplierName">
                        <el-select style="width: 100%" v-model="recordForm.deviceSupplierName" placeholder="请选择设备供应商">
                            <el-option label="深圳市博实结科技有限公司" value="深圳市博实结科技有限公司" key="深圳市博实结科技有限公司"></el-option>
                            <el-option label="深圳市赛格导航科技股份有限公司" value="深圳市赛格导航科技股份有限公司" key="深圳市赛格导航科技股份有限公司" ></el-option>
                            <el-option label="北京自航世纪科技公司" value="北京自航世纪科技公司" key="北京自航世纪科技公司" ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="流量类型" prop="internetTrafficType">
                        <el-input v-model="recordForm.internetTrafficType" maxlength="17" placeholder="请输入流量类型" clearable />
                    </el-form-item>
                    <el-form-item label="流量大小" prop="internetTraffic">
                        <el-input v-model="recordForm.internetTraffic" maxlength="17" placeholder="请输入流量大小" clearable />
                    </el-form-item>
                    <el-form-item label="库存状态" prop="stockStatus">
                        <el-select style="width: 100%" v-model="recordForm.stockStatus" placeholder="请选择变更状态">
                            <el-option label="入库" value="0" key="0"></el-option>
                            <el-option label="出库" value="1" key="1" ></el-option>
                        </el-select>
                    </el-form-item>
           
                    <el-form-item label="采购时间" prop="purchaseTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.purchaseTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择采购时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="厂家发货时间" prop="supplierSendTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.supplierSendTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择厂家发货时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="入库时间" prop="inStorageTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.inStorageTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择入库时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="出库时间" prop="outStorageTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.outStorageTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择出库时间"> 
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="设备激活时间" prop="activateTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.activateTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择设备激活时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="绑定车辆时间" prop="bindTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.bindTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择绑定车辆时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="续费时间" prop="renewTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.renewTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择续费时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="厂商过期时间" prop="supplierExpirationTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.supplierExpirationTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择厂商过期时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="租户过期时间" prop="tenantExpirationTime">
                        <el-date-picker 
                            style="width: 100%" 
                            v-model="recordForm.tenantExpirationTime" 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm:ss" 
                            value-format="yyyy-MM-dd HH:mm:ss" 
                            placeholder="请选择租户过期时间"> 
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="库管" prop="warehouseOperator">
                        <el-input v-model="recordForm.warehouseOperator" maxlength="17" placeholder="请输入库管" clearable />
                    </el-form-item>
                    <el-form-item label="质检" prop="qualityInspector">
                        <el-input v-model="recordForm.qualityInspector" maxlength="17" placeholder="请输入质检" clearable />
                    </el-form-item>
                    <el-form-item label="业务操作员" prop="businessOperator">
                        <el-input v-model="recordForm.businessOperator" maxlength="17" placeholder="请输入业务操作员" clearable />
                    </el-form-item>
                    <el-form-item label="客户总公司" prop="rootTenantId">
                        <el-select v-model="recordForm.rootTenantId" placeholder="请选择客户总公司" @change="changeTenant" clearable>
                            <el-option v-for="item in tenants" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户分公司" prop="subTenantId">
                        <el-select style="width: 100%" v-model="recordForm.subTenantId" placeholder="请选择客户分公司" @change="changeCompany" clearable> 
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户车队" prop="fleetId">
                        <el-select style="width: 100%" v-model="recordForm.fleetId" placeholder="请选择客户车队" clearable>
                            <el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
          
                    <el-form-item label="项目状态" prop="projectStatus">
                        <el-input v-model="recordForm.projectStatus" placeholder="请输入项目状态" clearable />
                    </el-form-item>
                    <el-form-item label="设备状态" prop="deviceStatus">
                        <el-input v-model="recordForm.deviceStatus" placeholder="请输入设备状态" clearable />
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="recordForm.remark" placeholder="请输入备注" clearable />
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="doEditRecord(false)">取 消</el-button>
                    <el-button type="primary" @click="doEditRecord(true)">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 添加批次对话框 -->
            <el-dialog :title="title" :visible.sync="uploadRecordsVisible" width="600px" append-to-body>
                <el-form ref="uploadForm" :model="uploadForm" label-width="120px">
                    <el-form-item label="设备Excel" prop="file">
                        <el-upload class="upload-demo" ref="upload" :file-list="fileList" :on-change="handleFileChange" :on-remove="handleRemove" :disabled="loading" :auto-upload="false">
                            <el-button size="small" type="primary" :disabled="loading">选择文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="beforeUpload(file)" :disabled="loading">确 定</el-button>
                    <el-button @click="uploadRecordsVisible = false">取 消</el-button>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'bindManager',
    data() {
        return {
            title: '添加',
            loading: false,
            recordFormVisible: false,
            uploadRecordsVisible: false,
            tenants: [
                { label: '环球车享', value: 10004 },
                { label: '太保商用车', value: 10049 }
            ],
            rootTenantId: null,
            subTenantId: null,
            companyList: [],
            fleetList: [],
            queryParams: {
                condition: {},
                pageNo: 1,
                pageSize: 10
            },

            recordList: [],
            total: 0,
            recordForm: {
            },
            uploadForm: {},
            fileList: [],
            rules: {
                deviceNumber: [
                    { required: true, message: '设备号不能为空', trigger: 'blur' },
                    { min: 10, max: 15, message: '设备号长度在 10 到 15 位', trigger: 'blur' }
                ]
            },
            baseURL: ''
        };
    },
    watch: {},
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    created() {
        //this.fetchCompanyList();
        this.fetchRecordList();
    },
    methods: {
        downloadTemplate() {},
        uploadRecords() {
            this.fileList = [];
            this.file = null;
            this.uploadRecordsVisible = true;
        },

        handleFileChange(file, fileList) {
            this.file = file.raw;
            this.fileList = [this.file];
        },
        handleRemove(file, fileList) {
            this.file = undefined;
            this.fileList = [];
        },
        beforeUpload(file) {
            //this.disabled = true;
            let _this = this;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                _this.fileString = reader.result.replace('data:', '').replace(/^.+,/, '');
                _this.doUploadRecord(_this.fileString);
            };
            //this.disabled = false;
            return false;
        },

        doUploadRecord(fileContent) {
            this.loading = true;
            const data = {
                file: fileContent,
                rootTenantId: this.rootTenantId
            };
            this.instance
                .post('/operate/api/device/addStockRecordBatch', data)
                .then((res) => {
                    this.loading = false;
                    if (res.data.code === 200) {
                        this.$message.success('上传成功');
                        this.resetWithLoadRecord();
                        this.uploadRecordsVisible = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    this.$message.error('请求失败! 请稍后重试');
                });
        },
    
        addRecord() {
            this.recordFormVisible = true;
            this.$refs['recordForm'].resetFields();
            this.recordForm = {};
        },
        editRecord(row) {
            this.recordFormVisible = true;
            if(this.$refs['recordForm']) {
                this.$refs['recordForm'].resetFields();
            }
            this.recordForm = {
                ...row
            };
        },
        doEditRecord(submitFlag) {
            if (submitFlag) {
                this.$refs['recordForm'].validate((valid) => {
                    if (valid) {
                        this.submitRecord();
                    }
                });
            } else {
                this.$refs.recordForm && this.$refs.recordForm.resetFields();
                this.recordFormVisible = false;
            }
        },
        submitRecord() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.recordForm));
            this.instance.post('/operate/api/device/stock/save', data).then((res) => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.recordFormVisible = false;
                    this.$message.success('提交成功!');
                    this.resetWithLoadRecord();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        downloadTable() {
            var queryJson = JSON.stringify(this.queryParams.condition);
            var buf = Buffer.from(queryJson, 'utf-8');
            var base64 = buf.toString('base64')
            window.open(this.baseURL + '/operate/api/device/downloadStockRecordsExcel?base64=' + base64, '_blank');
        },
        changeTenant(rootTenantId) {
            this.rootTenantId = rootTenantId;
            this.fetchCompanyList();
        },
        changeCompany(subTenantId) {
            this.subTenantId = subTenantId;
            this.fetchFleetList();
        },
        fetchCompanyList() {
            this.instance.get('/operate/api/tenant/companyList?rootTenantId=' + this.rootTenantId).then((res) => {
                this.companyList = res.data.data;
            });
        },
        fetchFleetList() {
            var url = `/operate/api/tenant/fleetList?companyId=${this.subTenantId}&rootTenantId=${this.rootTenantId}`
            this.instance.get(url).then((res) => {
                this.fleetList = res.data.data;
            });
        },
        fetchRecordList() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.queryParams));
            
            this.instance.post('/operate/api/device/stockRecords', data).then((res) => {
                this.loading = false;
                this.recordList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        search(searchText) {
            this.reset();
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {
                    search: searchText
                }
            };
            this.fetchBindRecordList();
        },
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.fetchRecordList();
        },
        resetQuery() {
            this.queryParams = {
                pageNo: 1,
                pageSize: 10,
                condition: {}
            };
        },
        reset() {
            this.resetQuery();
            this.bindRecordList = [];
            this.fileList = [];
            this.file = undefined;
            this.recordForm = {};
        },
        resetWithLoadRecord() {
            this.reset();
            this.fetchRecordList();
        }
    }
};
</script>